<template>
  <v-app id="sandbox">
    <LeftSidebar></LeftSidebar>
    <MainHeader></MainHeader>
    <RightSidebar></RightSidebar>
    <ConnectSidebar></ConnectSidebar>
    <CardSidebar></CardSidebar>
    <MessageSidebar></MessageSidebar>
    <NotificationSidebar></NotificationSidebar>
    <v-main style="background: #f2f1f7">
      <v-container fluid style="background: #f2f1f7">
        <v-row dense>
          <v-col cols="12">
            <v-card class="rounded-10" min-height="500">
              <v-tabs show-arrows>
                <v-tabs-slider color="success"></v-tabs-slider>
                <v-tab
                  v-for="(tab, index) in tabs"
                  :key="index"
                  active-class="primary light--text"
                  @click.stop="handleChangeTab(tab)"
                >
                  {{ tab.title }}
                </v-tab>
              </v-tabs>
              <router-view :key="$route.fullPath" />
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer :inset="footer.inset" app v-if="hasFooter">
      <span class="px-4">&copy; {{ new Date().getFullYear() }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import LeftSidebar from "@/modules/Dashboard/components/LeftSidebar.vue";
import RightSidebar from "@/modules/Dashboard/components/RightSidebar.vue";
import ConnectSidebar from "@/modules/Dashboard/components/ConnectSidebar.vue";
import CardSidebar from "@/modules/Dashboard/components/CardSidebar.vue";
import MainHeader from "@/modules/Dashboard/components/MainHeader.vue";
import MessageSidebar from "@/modules/Dashboard/components/MessageSidebar.vue";
import NotificationSidebar from "@/modules/Dashboard/components/NotificationSidebar.vue";
export default {
  name: "Dashboard",
  components: {
    LeftSidebar,
    MainHeader,
    RightSidebar,
    ConnectSidebar,
    CardSidebar,
    MessageSidebar,
    NotificationSidebar,
  },
  data: () => ({
    footer: {
      inset: true,
    },
    tabs: [
      { title: "General", route: { name: "admin-dashboard" } },
      { title: "APIs", route: { name: "admin-apis" } },
      { title: "Subscribers", route: { name: "admin-subscribers" } },
      // { title: "Products", route: { name: "admin-products" } },
      { title: "Payments", route: { name: "admin-payments" } },
      { title: "Templates", route: { name: "admin-templates" } },
      { title: "Database", route: { name: "admin-database" } },
      { title: "Logs", route: { name: "admin-logs" } },
    ],
  }),
  mounted() {
    this.$store.commit("dashitems/init_forSelection", this.user_roles);
  },
  computed: {
    ...mapGetters(["user_roles"]),
    ...mapGetters("app", ["hasFooter"]),
  },
  methods: {
    handleChangeTab(tab) {
      this.$router.push(tab.route).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
 
</style>